<template>
  <div class="mb-20">
    <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
      <div class="flex flex-wrap items-center col-span-1">
        <h2 class="mr-3 text-2xl font-bold">User Management</h2>
      </div>
      <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, id, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
      <div v-if="areUsersSelected()">
        <button
          class="btn btn-sm btn-blue"
          @click="$refs.addSelectedUsersToCategory.open()"
        >
          Add Users to new Category
        </button>
        <button
          class="btn btn-sm btn-blue ml-5"
          @click="$refs.openUpdateCategoryUsersModal.open()"
        >
          Add Users to existing Category
        </button>
      </div>
    </div>

    <div class="border border-blue-200 border-solid">
      <div class="flex">
        <div class="duration-300 w-full">
          <datatable
            :url="url"
            :ajax="true"
            :ajaxPagination="true"
            :index="true"
            :reverse-index="true"
            :data="users.data"
            :selectable="true"
            dropdown="actions"
            :pageDetails="true"
            :filters="filters"
            :columns="columns"
            :query="searchQuery"
            :loading="users.loading"
            ref="table"
          />
        </div>
      </div>
    </div>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="addSelectedUsersToCategory"
    >
      <h3 class="font-bold mb-5 text-xl">
        Add Selected Users To A New Category
      </h3>
      <div>
        <template v-if="getFormError(newCategoryForm)">
          <div class="alert alert-red mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(newCategoryForm) }}</span>
          </div>
        </template>
      </div>
      <form @submit.prevent="submitNewCategory" class="flex flex-col flex-grow">
        <form-group
          v-model="newCategoryForm.data.name.value"
          :form="newCategoryForm"
          name="name"
          type="textarea"
        >
          Category Name
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.addSelectedUsersToCategory.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="newCategoryForm.loading"
          >
            <sm-loader-white v-if="newCategoryForm.loading" />
            <span v-else>Add new Category</span>
          </button>
        </div>
      </form>
    </modal>
    <modal
      class-name="flex flex-col w-full sm:max-w-500px max-h-full min-h-400px"
      ref="openUpdateCategoryUsersModal"
    >
      <h3 class="font-bold mb-5 text-xl">Add To Existing Category</h3>
      <form
        @submit.prevent="submitUpdateCategoryUsers"
        class="flex flex-col flex-grow"
      >
        <form-group
          v-model="updateCategoryUsersForm.data.category_id.value"
          :form="updateCategoryUsersForm"
          name="category_id"
          type="select"
          :options="categories"
        >
          Select a category
        </form-group>

        <div class="flex items-center gap-4 mt-auto">
          <button
            type="button"
            class="btn btn-gray btn-md"
            @click.prevent="$refs.openUpdateCategoryUsersModal.close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-blue btn-md w-full"
            :disabled="updateCategoryUsersForm.loading"
          >
            <sm-loader-white v-if="updateCategoryUsersForm.loading" />
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedUsers: [],
      categories: [],
      url: `${this.$baseurl}/push-notifications/users`,
      columns: [
        {
          name: 'name',
          th: 'Name',
          render: (user) => user.name + ' ' + user.last_name,
        },
        {
          name: 'email',
          th: 'Email Address',
        },
      ],
      filters: [
        { title: 'all', name: 'all' },
        // { title: 'Invest Users', name: 'invest-users' },
        { title: 'Card Users', name: 'card-users' },
        { title: 'Loan Users', name: 'loan-users' },
      ],
      selectedUser: null,
      users: this.$options.resource([]),
      newCategoryForm: this.$options.basicForm([
        { name: 'name', rules: 'required' },
      ]),
      updateCategoryUsersForm: this.$options.basicForm([
        { name: 'category_id', rules: 'required' },
      ]),
      loading: null,
      searchQuery: '',
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      this.loading = true;
      await this.sendRequest('admin.pushNotifications.getCategories', {
        success: (response) => {
          this.categories = response.data.datatable.data.map((category) => ({
            title: category.name,
            value: category.id,
          }));
        },
        error: (error) => {
          console.log(error, 'error fetching categories');
        },
      });
    },
    getSelectedUsers() {
      const tableComponent = this.$refs.table;
      const selectedItems = tableComponent?.selected;
      this.selectedUsers = selectedItems?.map(
        (selectedUser) => selectedUser.row?.id
      );
    },

    areUsersSelected() {
      const tableComponent = this.$refs.table;
      const selectedItems = tableComponent?.selected;
      return selectedItems?.length > 0;
    },

    async submitNewCategory() {
      if (!this.validateForm(this.newCategoryForm)) return;

      this.newCategoryForm.loading = true;
      this.newCategoryForm.error = null;
      this.getSelectedUsers();
      const payload = {
        userIds: this.selectedUsers,
        ...this.getFormData(this.newCategoryForm),
      };
      await this.sendRequest(
        'admin.pushNotifications.addNewCategoryWithUsers',
        {
          data: {
            ...payload,
          },
          success: (response) => {
            this.$success({
              title: 'Users Added to Category',
              body: `Users have been successfully added to the new category: ${response.data.data.name}`,
              button: 'Okay',
            });
            this.newCategoryForm = this.resetForm(this.newCategoryForm);
            this.$router.push({
              name: 'push-notifications/category',
              params: {
                id: response.data.data.id,
              },
            });
            this.$emit('success');
            this.$refs.addSelectedUsersToCategory.close();
          },
          error: (error) => {
            this.newCategoryForm.error = error;
            this.mapFormErrors(
              this.newCategoryForm,
              error.response?.data?.errors
            );
          },
        }
      );
      this.newCategoryForm.loading = false;
    },
    async submitUpdateCategoryUsers() {
      if (!this.validateForm(this.updateCategoryUsersForm)) return;

      this.updateCategoryUsersForm.loading = true;
      this.updateCategoryUsersForm.error = null;
      this.getSelectedUsers();
      const payload = {
        userIds: this.selectedUsers,
        ...this.getFormData(this.updateCategoryUsersForm),
      };
      await this.sendRequest('admin.pushNotifications.updateCategoryUsers', {
        data: {
          ...payload,
        },
        success: () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Users has been synced to category',
            button: 'Okay',
          });
          this.updateCategoryUsersForm = this.resetForm(
            this.updateCategoryUsersForm
          );
          this.$emit('success');
          this.$refs.openUpdateCategoryUsersModal.close();
        },
        error: (error) => {
          this.updateCategoryUsersForm.error = error;
          this.mapFormErrors(
            this.updateCategoryUsersForm,
            error.response?.data?.errors
          );
        },
      });
      this.updateCategoryUsersForm.loading = false;
    },
  },
};
</script>
